<template>
  <main v-if="country">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ country.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'settings.countries.edit', params: { code: country.code } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div class="container">
      <div class="row">
        <div class="col">
          <p><strong>Codice</strong>: {{ country.code }}</p>
        </div>
        <div class="col">
          <p><strong>Valuta</strong>: {{ country.currencyName }} ({{ country.currencyCode }})</p>
        </div>
        <div class="col">
          <p><strong>Attivo</strong>: {{ country.enabled ? 'Sì' : 'No' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Aliquote IVA</strong>
          <div v-if="!country.vatRates || country.vatRates.length === 0">Nessuna aliquota impostata</div>
          <div v-else-if="country.vatRates.length === 1 && !country.vatRates[0].min && !country.vatRates[0].max">
            {{ country.vatRates[0].vatRate }}%
          </div>
          <template v-else>
            <div v-for="(vatRate, pos) in country.vatRates" :key="'vatRate-' + pos">
              {{ vatRate.vatRate }}% ({{ vatRate.min }}-{{ vatRate.max }})
            </div>
          </template>
        </div>
        <div class="col">
          <strong>Accise sul vino</strong>
          <div v-if="!country.exciseDuties || country.exciseDuties.length === 0">Nessuna accisa impostata</div>
          <div v-else-if="country.exciseDuties.length === 1 && !country.exciseDuties[0].min && !country.exciseDuties[0].max">
            {{ country.exciseDuties[0].exciseDuty / 100 }} {{ country.currencyCode }}/HL
          </div>
          <template v-else>
            <div v-for="(exciseDuty, pos) in country.exciseDuties" :key="'exciseDuty-' + pos">
              {{ exciseDuty.exciseDuty / 100 }} {{ country.currencyCode }}/HL ({{ exciseDuty.min }}-{{ exciseDuty.max }})
            </div>
          </template>
        </div>
        <div class="col">
          <strong>Accise sugli spumanti</strong>
          <div v-if="!country.sparklingExciseDuties || country.sparklingExciseDuties.length === 0">Nessuna accisa impostata</div>
          <div v-else-if="country.sparklingExciseDuties.length === 1 && !country.sparklingExciseDuties[0].min && !country.sparklingExciseDuties[0].max">
            {{ country.sparklingExciseDuties[0].exciseDuty / 100 }} {{ country.currencyCode }}/HL
          </div>
          <template v-else>
            <div v-for="(exciseDuty, pos) in country.sparklingExciseDuties" :key="'exciseDuty-' + pos">
              {{ exciseDuty.exciseDuty / 100 }} {{ country.currencyCode }}/HL ({{ exciseDuty.min }}-{{ exciseDuty.max }})
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="container mt-3">
      <div class="row">
        <div class="col-auto">
          <router-link class="btn btn-outline-primary" :to="{ name: 'settings.countries.prices', params: { code: country.code } }">Visualizza listino</router-link>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" @click.prevent="downloadPrices">Download listino</button>
        </div>
      </div>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import { saveBlobAsFile } from '@/libs/Downloader/helpers/saveStringAsFile.js';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.code) {
    return next(from);
  }

  $api.fetchCountry(to.params.code)
    .then(res => next(vm => {
      vm.country = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      country: null,
    };
  },
  methods: {
    downloadPrices () {
      this.$api.getCountryProductPricesAsCsv(this.country.code)
        .then(res => {
          return saveBlobAsFile(`${this.country.name}.csv`, res.data);
        })
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
